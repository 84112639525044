import { Document, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getCompanyLogoFromProject } from '../../helper/PackageJsonHelper';
import { getResource } from '../../helper/ResourcesHelper';

// var img = getCurrentURLImage();
var img = `${window.location.origin}${getCompanyLogoFromProject()}`;
var checked = require('../../css/images/black-check-box.png');
var checkbox = require('../../css/images/blank-check-box.png');

const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const saveIndividualPdf = async (document, filename) => {
  saveBlob(await pdf(document).toBlob(), filename);
};

export const saveIndividualPdfBuffer = async (document) => {
  var blob = await pdf(document).toBlob();
 // var url = window.URL.createObjectURL(blob); 
  return blob;
};

const addPropertiesStep = (obj) => (
  <View style={styles.section}>
    <View style={styles.columnDetails}>
      <Text style={styles.textLabel}>{obj.description}</Text>
      <Text style={styles.boxText}>
        <Text style={styles.textSmall}>
        {obj.value !== '' ?
            obj.value : ' '}
        </Text>
      </Text>
    </View>
  </View>
);

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30
  },
  section: {
    flexDirection: 'row',
  },
  imageSection: {
    padding: 20,
    marginBottom: 10,
    borderBottom: 1,
    borderBottomColor: '#555555',
    //backgroundColor: '#00548d',
    flexDirection: 'row',
  },
  titleHeader: {
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'center',
    color: '#666',
    fontSize: 16,
    fontWeight: 'ultrabold'
  },
  footerSection: {
    padding: 10,
    paddingTop: 30,
    borderTop: 1,
    borderBottomColor: '#555555',
    color: '#fff',
    backgroundColor: '#00548d',
    flexDirection: 'row',
    position: 'relative',
  },
  columnDetails: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  paragraph: {
    fontSize: 8,
    justifyContent: 'space-between',
    marginTop: 10,
    paddingBottom: 10
  },
  textLarge: {
    fontSize: 12,
    marginTop: 5,
    paddingTop: 5,
  },
  subtitle: {
    color: '#13417a',
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
    fontWeight: 700
  },
  textLabel: {
    fontSize: 6,
    marginTop: 5,
    paddingTop: 5,
    marginBottom: 1
  },
  textLabelBottom: {
    fontSize: 6,
    marginBottom: 5,
    paddingBottom: 5,
    marginTop: 1
  },
  textSmall: {
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
  },
  boxText: {
    border: 0.5,
    borderRadius: 5,
    borderBottomColor: '#555555',
    padding: 5,
  },
  boxDeclarationSignature: {
    borderBottom: 0.5,
    borderBottomColor: '#555555',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    width: 270, 
    height: 50
  },
  boxDeclarationSignatureByImg: {
    borderBottom: 0.5,
    borderBottomColor: '#555555',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    paddingRight: 35, 
    paddingLeft: 35,
  },
  signatureImg: {
    width: 200, 
    height: 50
  },
  boxDeclarationDate: {
    //border: 0.5,
    borderBottomColor: '#555555',
    padding: 5,
    lineHeight: 6,
    flexGrow: 2
  },
  boxDeclarationFullName: {
    //border: 0.5,
    borderBottomColor: '#555555',
    padding: 5,
    lineHeight: 2
  },
  bulletPoint: {
    width: 6
  },
  spaceTopBottom: {
    paddingBottom: 12
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  }
});


//Documents for Steps
/* --------------------STEP 1------------------- */
const ApplicantGeneralInformation = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.subtitle}>{getResource('Title_1')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
      <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.FirstName !== '' && obj.FirstName != null) ?
              obj.FirstName : ' '}
          </Text>
        </Text>
                
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.DateOfBirth !== '' && obj.DateOfBirth != null) ?
              obj.DateOfBirth : ' '}
          </Text>
        </Text>
      </View>

      {/*COLUMN 2 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ShortName !== '' && obj.ShortName != null) ?
              obj.ShortName : ' '}
          </Text>
        </Text>

        <Text style={styles.textLabel}>{getResource('Label_Citizenship')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.CitizenshipName !== '' && obj.CitizenshipName != null) ?
              obj.CitizenshipName : ' '}
          </Text>
        </Text>
      </View>

      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
      <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.LastName !== '' && obj.LastName != null) ?
              obj.LastName : ' '}
          </Text>
        </Text>

        <Text style={styles.textLabel}>{getResource('Label_AffiliateReferredBy')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.AffiliateReferredBy !== '' && obj.AffiliateReferredBy != null) ?
              obj.AffiliateReferredBy : ' '}
          </Text>
        </Text>       
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStreet !== '' && obj.ResidentialAddressStreet != null) ?
              obj.ResidentialAddressStreet : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_AddressLine2')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressLine2 !== '' && obj.ResidentialAddressLine2 != null) ?
              obj.ResidentialAddressLine2 : ' '}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressCity !== '' && obj.ResidentialAddressCity != null) ?
              obj.ResidentialAddressCity : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStateName !== '' && obj.ResidentialAddressStateName != null) ?
              obj.ResidentialAddressStateName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressPostalCode !== '' && obj.ResidentialAddressPostalCode != null) ?
              obj.ResidentialAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_MainContactNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialPhoneNumber !== '' && obj.ResidentialPhoneNumber != null) ?
              obj.ResidentialPhoneNumber : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.EmailAddress !== '' && obj.EmailAddress != null) ?
              obj.EmailAddress : ' '}
          </Text>
        </Text>
      </View>
    </View>

     {/*Personal Identification */}
     <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryNameIdentification !== '' && obj.countryNameIdentification != null) ?
              obj.countryNameIdentification : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj.documentTypeID.includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.numberIdentification !== '' && obj.numberIdentification != null) ?
                obj.numberIdentification : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.DriverLicenseNumber !== '' && obj.DriverLicenseNumber != null) ?
                obj.DriverLicenseNumber : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PassportNumber !== '' && obj.PassportNumber != null) ?
                obj.PassportNumber : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    {
      addObj.step1.length > 0 &&
      <View>

        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>

        {
          addObj.step1.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 2------------------- */
const AccountPurpose = (addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_2')}</Text>
    {
      addObj.step2.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step2.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 2------------------- */
const PreliminaryDetails = (obj, addObj) => (
  <View break={true}>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.subtitle}>{getResource('Title_2')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_EmployerName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.EmployerName !== '' && obj.EmployerName != null) ?
              obj.EmployerName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_TitleOccupation')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.TitleOccupationIDName !== '' && obj.TitleOccupationIDName != null) ?
              obj.TitleOccupationIDName : ' '}
          </Text>
        </Text>
      </View>
    </View>
    {
      addObj.step2.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step2.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);
/* --------------------STEP 3------------------- */
const DetailsAbout = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.subtitle}>{getResource('Title_3')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_CurrenciesInterested')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkEUR &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkEUR &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_EUR')}</Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkGBP &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkGBP &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_GBP')}</Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkUSD &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkUSD &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_USD')}</Text>
      </View>
      {/*COLUMN 4 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkAED &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkAED &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_AED')}</Text>
      </View>
      {/*COLUMN 5 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkOther &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {obj.CurrenciesInterestedTradingOther}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_AmountPerMonth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.AmountTradedMonthIDName !== '' && obj.AmountTradedMonthIDName != null) ?
              obj.AmountTradedMonthIDName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_PurposeForExchanging')}</Text>
        {!obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PurposeExchangingCurrencyIDName !== '' && obj.PurposeExchangingCurrencyIDName != null) ?
                obj.PurposeExchangingCurrencyIDName : ' '}
            </Text>
          </Text>
        }
        {obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PurposeExchangingCurrencyOther !== '' && obj.PurposeExchangingCurrencyOther != null) ?
                obj.PurposeExchangingCurrencyOther : ' '}
            </Text>
          </Text>
        }
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_HearAboutUs')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {obj.clickRadio}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {obj.clickRadio === obj.SalesCallState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_SalesCall')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsSalesCall !== '' && obj.WhereYouHearAboutUsSalesCall != null) ?
                obj.WhereYouHearAboutUsSalesCall : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.ReferralState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Referral')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.DetailsAbout.WhereYouHearAboutUsReferral !== '' && obj.DetailsAbout.WhereYouHearAboutUsReferral != null) ?
                obj.DetailsAbout.WhereYouHearAboutUsReferral : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.EventState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Event')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsEvent !== '' && obj.WhereYouHearAboutUsEvent != null) ?
                obj.WhereYouHearAboutUsEvent : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.OtherState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Other')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsOther !== '' && obj.WhereYouHearAboutUsOther != null) ?
                obj.WhereYouHearAboutUsOther : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>
    {
      addObj.step3.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step3.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);
/* --------------------STEP 4------------------- */
const AuthorisedPersons = (obj, trader) => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>SHAREHOLDER / OWNER {trader}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['FirstNameTrader0' + trader] !== '' && obj['FirstNameTrader0' + trader] != null) ?
              obj['FirstNameTrader0' + trader] : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['DateOfBirthTrader0' + trader] !== '' && obj['DateOfBirthTrader0' + trader] != null) ?
              obj['DateOfBirthTrader0' + trader] : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['LastNameTrader0' + trader] !== '' && obj['LastNameTrader0' + trader] != null) ?
              obj['LastNameTrader0' + trader] : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressStreetTrader0' + trader] !== '' && obj['ResidentialAddressStreetTrader0' + trader] != null) ?
              obj['ResidentialAddressStreetTrader0' + trader] : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_AddressLine2')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressLine2Trader0' + trader] !== '' && obj['ResidentialAddressLine2Trader0' + trader] != null) ?
              obj['ResidentialAddressLine2Trader0' + trader] : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressCityTrader0' + trader] !== '' && obj['ResidentialAddressCityTrader0' + trader] != null) ?
              obj['ResidentialAddressCityTrader0' + trader] : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {obj['ResidentialAddressStateNameTrader0' + trader]}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['countryNameTrader0' + trader] !== '' && obj['countryNameTrader0' + trader] != null) ?
              obj['countryNameTrader0' + trader] : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressPostalCodeTrader0' + trader] !== '' && obj['ResidentialAddressPostalCodeTrader0' + trader] != null) ?
              obj['ResidentialAddressPostalCodeTrader0' + trader] : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_ResidentialPhoneNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialPhoneNumberTrader0' + trader] !== '' && obj['ResidentialPhoneNumberTrader0' + trader] != null) ?
              obj['ResidentialPhoneNumberTrader0' + trader] : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['EmailAddressTrader0' + trader] !== '' && obj['EmailAddressTrader0' + trader] != null) ?
              obj['EmailAddressTrader0' + trader] : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_MobileNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['MobileNumberTrader0' + trader] !== '' && obj['MobileNumberTrader0' + trader] != null) ?
              obj['MobileNumberTrader0' + trader] : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_PoliticallyExposed')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {obj['radio' + trader]}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['countryNameIdentificationTrader0' + trader] !== '' && obj['countryNameIdentificationTrader0' + trader] != null) ?
              obj['countryNameIdentificationTrader0' + trader] : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj['documentTypeIDTrader0' + trader].includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['numberIdentificationTrader0' + trader] !== '' && obj['numberIdentificationTrader0' + trader] != null) ?
                obj['numberIdentificationTrader0' + trader] : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj['documentTypeIDTrader0' + trader].includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['DriverLicenseNumberTrader0' + trader] !== '' && obj['DriverLicenseNumberTrader0' + trader] != null) ?
                obj['DriverLicenseNumberTrader0' + trader] : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj['documentTypeIDTrader0' + trader].includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['PassportNumberTrader0' + trader] !== '' && obj['PassportNumberTrader0' + trader] != null) ?
                obj['PassportNumberTrader0' + trader] : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    {/* Attachments 
    {
      obj['documentTypeIDTrader0' + trader].includes('Identity') &&
      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification1')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['frontPITrader0' + trader] !== '' && obj['frontPITrader0' + trader] != null) ?
                obj['frontPITrader0' + trader][0].filename : (
                  (obj['personalIdentification1FileNameTrader0' + trader] !== '' && obj['personalIdentification1FileNameTrader0' + trader] != null) ?
                    obj['personalIdentification1FileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification2')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['endPITrader0' + trader] !== '' && obj['endPITrader0' + trader] != null) ?
                obj['endPITrader0' + trader][0].filename : (
                  (obj['personalIdentification2FileNameTrader0' + trader] !== '' && obj['personalIdentification2FileNameTrader0' + trader] != null) ?
                    obj['personalIdentification2FileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
        </View>
      </View>
    }
    {
      obj['documentTypeIDTrader0' + trader].includes('Driving') &&
      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification1')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['frontDLTrader0' + trader] !== '' && obj['frontDLTrader0' + trader] != null) ?
                obj['frontDLTrader0' + trader][0].filename : (
                  (obj['drivingLicense1FileNameTrader0' + trader] !== '' && obj['drivingLicense1FileNameTrader0' + trader] != null) ?
                    obj['drivingLicense1FileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification2')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['endDLTrader0' + trader] !== '' && obj['endDLTrader0' + trader] != null) ?
                obj['endDLTrader0' + trader][0].filename : (
                  (obj['drivingLicense2FileNameTrader0' + trader] !== '' && obj['drivingLicense2FileNameTrader0' + trader] != null) ?
                    obj['drivingLicense2FileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
        </View>
      </View>
    }
    {
      obj['documentTypeIDTrader0' + trader].includes('Passport') &&
      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification1')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['frontPssTrader0' + trader] !== '' && obj['frontPssTrader0' + trader] != null) ?
                obj['frontPssTrader0' + trader][0].filename : (
                  (obj['passportFileNameTrader0' + trader] !== '' && obj['passportFileNameTrader0' + trader] != null) ?
                    obj['passportFileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
        </View>
      </View>
    }*/}
  </View>
);
/* --------------------STEP 5------------------- */

/* --------------------STEP DECLARATION------------------- */
const Declaration = () => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.subtitle}>{getResource('Title_Declaration')}</Text>
        <Text style={styles.paragraph}>{getResource('Label_Declaration')}</Text>
      </View>
    </View>
    <View style={[styles.section, { paddingTop: 10 }]}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        {/**------------SIGNATURE IMAGE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={styles.boxDeclarationSignatureByImg}>
            <Text style={styles.paragraph}>
              <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
            </Text>
          </Text>
          :
          <Text style={styles.boxDeclarationSignature}>
            <Text style={styles.paragraph}>
              {' '}
            </Text>
          </Text>
        }

        {/**------------SIGNATURE FULLNAME----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': ' + sessionStorage.getItem('signatureFullName')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': '
            }
          </Text>
        }
        {/**------------SIGNATURE DATE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': ' + sessionStorage.getItem('signatureDate')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': '
            }
          </Text>
        }
      </View>
    </View>
  </View>
);

// Create Document Component
export const MyDocumentIndividual = (props) => (
  <Document>
    <Page style={styles.page}>
      {/*-----------HEADER--------------*/}
      <View style={styles.imageSection} fixed={true}>
        <Image src={img} />
        <Text style={styles.titleHeader}> APPLICATION FORM </Text>
      </View>

      <View style={styles.section}>
        <View>
          <Text style={styles.textLarge}>{getResource('Individual_Title')}</Text>
          <Text style={styles.paragraph}>
            {getResource('Individual_Paragraph')}
            {getResource('Field_Required')}
          </Text>
          {/* allObjts:{
            ApplicantGeneralInformation

              PersonDetails
              PreliminaryDetails
              DetailsAbout
              AuthorisedPersons
              IdentificationDocuments
        }*/}
        </View>
      </View>
      {/*  ------------STEP 1--------------*/}
      {
        <View style={styles.spaceTopBottom}>
          {
            ApplicantGeneralInformation(props.obj.ApplicantGeneralInformation, props.obj.AdditionalProperties)
          }
        </View>
      }
      {/*  ------------STEP 2--------------*/}
      {
        <View style={styles.spaceTopBottom} break={true}>
          {
            AccountPurpose(props.obj.AdditionalProperties)
          }
        </View>
      }
      {/*  ------------STEP 3--------------
      {
        <View style={styles.spaceTopBottom}>
          {
            DetailsAbout(props.obj.DetailsAbout, props.obj.AdditionalProperties)
          }
        </View>
      }
      {/* ------------STEP 4-------------- 
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.subtitle}>{getResource('Title_4')}</Text>

            <Text style={styles.textLabel}>Will there be any other person authorised to trade on your behalf with this account?</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {props.obj.AuthorisedPersons.clickRadio}
              </Text>
            </Text>
          </View>
        </View>
        {/* ONLY YES
       SHAREHOLDER / OWNER 1
        {
          props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 1)
            }
          </View>
        }
        {
          (props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') && props.obj.AuthorisedPersons.trader02) &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 2)
            }
          </View>
        }
        {
          (props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') && props.obj.AuthorisedPersons.trader03) &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 3)
            }
          </View>
        }
        {
          (props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') && props.obj.AuthorisedPersons.trader04) &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 4)
            }
          </View>
        }
        {
          (props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') && props.obj.AuthorisedPersons.trader05) &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 5)
            }
          </View>
        }
      </View>
      */}

      {/* ------------STEP DECLARATION-------------- */}
      {
        <View style={styles.spaceTopBottom}>
          {
            Declaration()
          }
        </View>
      }

      {/*-----------FOOTER--------------
      <View style={styles.footerSection} fixed={true}>
        <View style={styles.columnDetails}>
          <Text>© Copyright 2011-{(new Date().getFullYear())} - E2E Foreign Exchange</Text>
          <Text>ABN: 22 151 337 852 | AFSL: 405948</Text>
        </View>
      </View>*/}
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed={true} />
    </Page>
  </Document>
);
const returnUrlBlob = (blob) => {
  let url = blob //window.URL.createObjectURL(blob);
  return url;
}
export const returnIndividualFileContent = async (document) => {
  return returnUrlBlob(await pdf(document).toBlob())
}